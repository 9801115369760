export default function () {
    const seminarIntro =
        '「大自然界」の生き方に興味のある方、あるいは学修を希望される方は、先ず「一般会員」として「大自然界 基本セミナー」にご参加ください。\n \
        「大自然界 基本セミナー」は、オフライン（各地域の学修グループにおける勉強会）、オンラインのどちらでも受講できます。'
    const seminarArticles = [
        {
            title: '「大自然界 基本セミナー」とは',
            article1:
                '「大自然界 基本セミナー」とは、大自然の『大親神大生命』が行って下さっている生命の営み、大自然の真理法則を学修する為の基本セミナーです。',
            list: [
                '大自然の事実を事実として認識し理解します。',
                '認識・理解・納得したものを、自分の実生活や生きる事に実際に生かしていきます。\n \
                この具体的方法も学修します。',
            ],
            article2:
                '「大自然界 基本セミナー」は、新しい生き方を悟る為の講習です。人種や民族・国家・宗教宗派・○○主義など、人間がこれまで持って来た考え方を超越した、生命本来の新しい生き方の講習です。様々な真理法則を単に知識として学ぶのではなく、自分の実生活・現実の人生に本当に役立つ悟りとして、身に付け修得していきます。',
        },
        {
            title: '「大自然界 基本セミナー」の講習内容',
            article:
                '「大自然界 基本セミナー」は6回の講習です。『大親神大生命』が実際に行って下さった親理のお授けを、音声テープを聞いて学修します。老若男女の差を超えて、より良く生きるために必要な大自然の真理法則を、誰でも分かりやすく学修する事ができます。',
            list: [
                'オリエンテーション',
                '生命の大親',
                '大自然の真理法則',
                '幸福の本質',
                'おつなぎ徳積みの理',
                '生命自覚宣誓の理',
            ],
        },
        {
            title: '「大自然界 基本セミナー」の効果',
            list: [
                {
                    title: '☆ 生きる事の意味や目的が分かる',
                    article:
                        '何の為に生きているのか、人生の目的、全生物の生存の目的を理解できます。『大親神大生命』が何の目的で全生物を誕生させ生かして下さっているかが分かります。',
                },
                {
                    title: '☆ 愛情豊かな人生になる',
                    article:
                        '家族や友人・知人はもとより、人類・全生物に対する生命愛が開発発展されて、愛情豊かな人生となります。また、その為の具体的方法も学修します。',
                },
                {
                    title: '☆ 永遠の生命の喜びと感謝',
                    article:
                        '肉体的死の恐怖がなくなります。肉体の誕生と死という事の意味が分かり、納得できて、“生きる事への感謝・味わい・喜び”が実生活の中で実感できます。',
                },
                {
                    title: '☆ 大自然の総合的認識・理解',
                    article:
                        '生命界・霊界・物質現象界という『大親神大生命』がお創り下さった世界を、総合的に認識し理解する事ができます。',
                },
                {
                    title: '☆ 理性的な自己分析',
                    article:
                        '大自然の真理法則をもとに、自分を冷静に・客観的に分析・理解します。これにより、他の人を理解したり、周りと調和できるようになります。',
                },
                {
                    title: '☆ 人生の幸福感・充実感の増大',
                    article:
                        'マイナス思考からプラス思考へ転換する方法を学び、日々の実生活の中で実践します。この事により、不安やストレスが解消され情緒が安定し、生きる喜びの実感、幸福感と充実感がより一層高まります。',
                },
            ],
        },
        {
            title: '「神学科」界員の講習内容',
            article:
                '「大自然界 基本セミナー」の6回の講習を修了された後、さらに上級へ進みたい方は、「神学科」の申し込みを行って下さい。\n \
                \n \
                「神学科」は、「生命自覚宣誓証」を行った界員の方を対象に行われます。そのため「神学科」の受講を希望される方は、“自分自身の本質は物質的な肉体ではなく、永遠なる生命です”という「生命自覚宣誓証」を行う事が必要です。',
            list: [
                '真参拝の理',
                'おつとめ親交式',
                'シンボルマークの理',
                '生命界・霊界・親物質界',
                '親子の原理',
                '生存の目的と意義',
                '観念の真転換',
                '能動的意志の力と働き',
                '十元の理法',
                '循環・還元の理法',
                '悟りと我慢の違い',
                '原因結果の理法',
                '欲求の段階',
                '役割機能の原則',
                '実行実現の原則',
                '生きる収穫',
                '有意味行為の原則',
                '出発発展の理法',
                '能動変化の理法',
                '徳と不徳',
                '平均・調和・安定の理法',
                'お願いつとめの理',
                '御守護の頂き方',
                '価値の段階',
                '主体性と責任転嫁',
                '機会・場の理法',
                '自明の理',
                '創設設定の原則',
                '種類分化の理法',
                'プラス善・マイナス悪',
                '知性・理性・感性',
                '強度継続の理法',
                '収穫と執着',
                '御親水・生命の水の理',
                '終止限界の理法',
                '責任の原則',
                '作用総合の理法',
                '神性と人間性',
                '神の子（真御子）宣誓',
            ],
        },
        {
            title: '「大自然界 基本セミナー」お申し込みフォーム ',
        },
    ]

    return { seminarIntro, seminarArticles }
}
