<template>
    <div id="Seminar">
        <section id="seminar-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">基本セミナー お申し込み</h1>
                <p class="f12-mb">
                    お申し込みフォームは、ページの最後にございます。
                </p>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-5.jpg'),
                        require('@/assets/background/background-5-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="seminar-title" class="title-section-style hide-mb">
            <h2 class="title f29">基本セミナー お申し込み</h2>
            <p class="subtitle f14">
                お申し込みフォームは、ページの最後にございます。
            </p>
        </section>
        <section id="seminar-main">
            <div class="wrap w80 w80-mb">
                <article class="article-style purple" id="seminar-intro">
                    <p class="w60 f14 f12-mb" v-html="wrap(seminarIntro)"></p>
                    <ul class="jump-list-style">
                        <li
                            v-for="(item, index) of seminarArticles"
                            :key="'seminar-menu-' + index"
                            class="f14 f12-mb"
                            @click="
                                scrollToElement('#seminar-article-' + index)
                            "
                        >
                            <i class="icon-page-link f12 f12-mb"></i
                            >{{ ' ' + item.title }}
                        </li>
                    </ul>
                </article>
                <article class="article-style purple" id="seminar-article-0">
                    <h2 class="f29 title f18-mb">
                        {{ seminarArticles[0].title }}
                    </h2>
                    <p
                        class="w60 f14 f12-mb"
                        v-html="wrap(seminarArticles[0].article1)"
                    ></p>
                    <ul class="w60 f16 f12-mb">
                        <li
                            v-for="(item, index) in seminarArticles[0].list"
                            :key="'seminar-article-0-' + index"
                            class="bold"
                        >
                            <span>{{ index + 1 }}.</span> {{ item }}
                        </li>
                    </ul>
                    <p
                        class="w60 f14 f12-mb"
                        v-html="wrap(seminarArticles[0].article2)"
                    ></p>
                </article>
                <article class="article-style purple" id="seminar-article-1">
                    <h2 class="f29 title">{{ seminarArticles[1].title }}</h2>
                    <p
                        class="w60 f14 f16-mb"
                        v-html="wrap(seminarArticles[1].article)"
                    ></p>
                    <ul class="f16 f12-mb">
                        <li
                            v-for="(item, index) in seminarArticles[1].list"
                            :key="'seminar-article-1-' + index"
                            class="bold"
                        >
                            ・{{ item }}
                        </li>
                    </ul>
                </article>
                <article class="article-style purple" id="seminar-article-2">
                    <h2 class="f29 title f18-mb">
                        {{ seminarArticles[2].title }}
                    </h2>
                    <ul class="w60">
                        <li
                            v-for="(item, index) in seminarArticles[2].list"
                            :key="'seminar-article-2-' + index"
                        >
                            <h3 class="f16 bold f18-mb">{{ item.title }}</h3>
                            <p class="f14 f12-mb">{{ item.article }}</p>
                        </li>
                    </ul>
                </article>
                <article class="article-style purple" id="seminar-article-3">
                    <h2 class="f29 title">{{ seminarArticles[3].title }}</h2>
                    <p
                        class="w60 f14 f12-mb"
                        v-html="wrap(seminarArticles[3].article)"
                    ></p>
                    <div class="f14 hide-mb">
                        <ul>
                            <li
                                v-for="(item, index) in courseList.slice(
                                    pcLength * 0,
                                    pcLength * 1
                                )"
                                :key="
                                    'seminar-article-3-' +
                                    (pcLength * 0 + index)
                                "
                            >
                                {{ item }}
                            </li>
                        </ul>
                        <ul>
                            <li
                                v-for="(item, index) in courseList.slice(
                                    pcLength * 1,
                                    pcLength * 2
                                )"
                                :key="
                                    'seminar-article-3-' +
                                    (pcLength * 1 + index)
                                "
                            >
                                {{ item }}
                            </li>
                        </ul>
                        <ul>
                            <li
                                v-for="(item, index) in courseList.slice(
                                    pcLength * 2,
                                    pcLength * 3
                                )"
                                :key="
                                    'seminar-article-3-' +
                                    (pcLength * 2 + index)
                                "
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                    <div class="f12-mb hide">
                        <ul>
                            <li
                                v-for="(item, index) in courseList.slice(
                                    mbLength * 0,
                                    mbLength * 1
                                )"
                                :key="
                                    'seminar-article-3-' +
                                    (mbLength * 0 + index)
                                "
                            >
                                {{ item }}
                            </li>
                        </ul>
                        <ul>
                            <li
                                v-for="(item, index) in courseList.slice(
                                    mbLength * 1,
                                    mbLength * 2
                                )"
                                :key="
                                    'seminar-article-3-' +
                                    (mbLength * 1 + index)
                                "
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </article>
            </div>
        </section>
        <section id="seminar-form">
            <article id="seminar-article-4">
                <SeminarForm></SeminarForm>
            </article>
        </section>
    </div>
</template>
<script>
export default {
    name: 'Seminar',
}
</script>
<script setup>
import { defineAsyncComponent } from 'vue'
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getSeminarData from '@/data/getSeminarData.js'
const SeminarForm = defineAsyncComponent(() =>
    import('@/components/SeminarForm.vue')
)
const { seminarIntro, seminarArticles } = getSeminarData()
const courseList = seminarArticles[3].list
const pcLength = Math.ceil(courseList.length / 3)
const mbLength = Math.ceil(courseList.length / 2)
</script>
<style lang="scss" scoped>
#seminar-main {
    .wrap {
        background-color: $white;
        border-radius: 20px;
    }
    #seminar-intro {
        ul {
            margin-top: 50px;
        }
    }
    #seminar-article-0,
    #seminar-article-1 {
        ul {
            margin: 30px auto;
        }
        li {
            line-height: 2.8em;
            padding-left: 1em;
            text-indent: -1em;
            @include underlineStyle();
            span {
                color: $majorPurple;
            }
        }
    }
    #seminar-article-1 {
        ul {
            width: fit-content;
            margin: 30px auto 0;
            display: grid;
            grid-template: auto / repeat(2, max-content);
            column-gap: 50px;
        }
    }

    #seminar-article-2 {
        li {
            position: relative;
            margin-top: 40px;
        }
    }
    #seminar-article-3 {
        border-bottom: none;
        div {
            width: fit-content;
            margin: 50px auto 0;
            display: grid;
        }
    }

    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        padding: 100px 0;
        #seminar-article-2 {
            ul {
                margin-top: 80px;
            }
            li::before {
                content: '';
                position: absolute;
                display: block;
                width: 8px;
                height: 90%;
                background-color: rgba($majorPurple, 0.3);
                left: -1.5em;
                top: 5%;
            }
        }
        #seminar-article-3 {
            div {
                grid-template: auto / repeat(3, max-content);
                column-gap: 60px;
                row-gap: 5px;
            }
        }
    }
    @media screen and(max-width:767px) {
        #seminar-article-3 {
            div {
                grid-template: auto / repeat(2, max-content);
                column-gap: 30px;
            }
        }
    }
}
#seminar-form {
    background-color: $minorGold;
}
</style>
